/* eslint-disable camelcase */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import StoryblokTemplate from "~templates/storyblok.jsx";
import { breakpoint } from "~utils/css.js";
import { useProjects } from "~hooks";
import { capitalizeString } from "~utils/helpers";

/** ============================================================================
 * @component
 * Case Study graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const StoryblokProject = ({ data, location, draftBridge }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const storyblokProjects = useProjects();

  const projectData = storyblokProjects?.data.find(
    (storyblokProject) => storyblokProject.id === data.storyblokEntry.id
  );

  // ---------------------------------------------------------------------------
  // variables

  // content parser

  const storyblokContent = data?.storyblokEntry?.content;

  const content =
    typeof storyblokContent === `string`
      ? JSON.parse(storyblokContent)
      : storyblokContent;

  const storyblokSeo = content?.seo;
  const defaultBlok = content?.bloks?.[0];

  //
  // seo title

  const workplaceTitle = data?.storyblokEntry?.full_slug?.split(`/`)?.[1];

  let title;

  if (typeof storyblokSeo?.title === `string` && storyblokSeo.title !== ``) {
    ({ title } = storyblokSeo);
  } else if (
    typeof defaultBlok?.heading === `string` &&
    defaultBlok.heading !== ``
  ) {
    title = `${defaultBlok.heading} ${
      workplaceTitle ? `| ${capitalizeString(workplaceTitle)} Project` : ``
    } | DIRTT`;
  }

  //
  // seo description

  let description;

  if (
    typeof storyblokSeo?.description === `string` &&
    storyblokSeo.description !== ``
  ) {
    ({ description } = storyblokSeo);
  } else if (
    typeof defaultBlok?.subheading === `string` &&
    defaultBlok.subheading !== ``
  ) {
    description = defaultBlok.subheading;
  }

  //
  // seo image

  let og_image;

  if (
    typeof storyblokSeo?.og_image === `string` &&
    storyblokSeo.og_image !== ``
  ) {
    ({ og_image } = storyblokSeo);
  }

  const seo = {
    ...storyblokSeo,
    title,
    description,
    og_image
  };

  // ---------------------------------------------------------------------------
  // render

  return (
    <StoryblokTemplate
      css={css`
        padding-top: calc(
          var(--header-height-touch) + var(--case-study-nav-height-desktop)
        );

        ${breakpoint(`large-tablet`)} {
          padding-top: calc(
            var(--header-height-desktop) + var(--case-study-nav-height-desktop)
          );
        }
      `}
      data={{ ...data, seo }}
      draftBridge={draftBridge}
      location={location}
      components={[
        { component: `ProjectNav` },
        { component: `Breadcrumbs`, background: `white`, color: `grey-70` }
      ]}
    />
  );
};

export default StoryblokProject;

export const query = graphql`
  query Project($project_id: String!, $id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id

      full_slug
      name
      content
    }

    allCloudinaryResource(filter: { project_id: { eq: $project_id } }) {
      edges {
        node {
          public_id
          width
          height
          credit
          caption
          alt
        }
      }
    }

    myDirttResource(project_id: { eq: $project_id }) {
      content {
        project_name
        tagline
        industry
        partner_name
        sq_ft
        city
        region
        country
        duration_install
        duration

        contacts {
          company
          name
          title
        }
      }
    }
  }
`;
